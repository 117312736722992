/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Loader, Message } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as utils from '../../utils';
import TopCover from '../../components/TopCover';
import OrganizationHeader from '../Organization/Header';

import { __ } from '../../i18n';
import FeaturesModal from '../../components/FeaturesModal';
import Container from '../../components/Container';
import SideMenuMobile from '../../components/SideMenuMobile';
import Footer from '../../components/Footer';

import { hasConversationProductFeature } from '../../helpers/hasConversationProductFeature';

import '../../assets/css/MainLayout.module.scss';
import { organizationShouldSeeConversation } from '../../helpers/organizationShouldSeeConversation';
import { formatUnleashStatusIntoToggle } from '../../helpers/formatUnleashStatusIntoToggles';

const organizationPages = ({ confCharge, features }) => ({
  READ_ENTITY: ['entities', 'channels'],
  READ_DASHBOARD: ['dashboard'],
  READ_GROUP: ['groups'],
  READ_MESSAGE: ['messages', 'moments', 'chats'].filter(f => features.messageManager !== 'BASIC' || f !== 'messages').filter(f => features.moments || f !== 'moments'),
  READ_REPORT: features.reports ? ['reports'] : [],
  READ_INTEGRATION: features.api ? ['integrations'] : [],
  READ_PAYMENT: features.charges && confCharge ? ['payments', 'charges'] : [],
  READ_FORM: features.forms ? ['forms'] : [],
  READ_ACCOUNT: features.charges && confCharge ? ['accounts'] : [],
  READ_LINK: features.links ? ['accesses'] : [],
  UPDATE_ORGANIZATION: ['edit'],
  READ_FILES: features.files ? ['files'] : [],
});

@inject('store')
@graphql(gql`query OrganizationNodeIdQuery ($id: ID!) {
  node(id: $id) @connection(key: "Organization", filter: ["id"])  {
    ... on Organization {
      id: dbId
      color
      live
      link
      type
      username
      fullname
      confMessage
      confMessageStudent
      confMessageStaff
      confMedia
      confCommitment
      confSurvey
      confCharge
      confReport
      confRecurrence
      confForm
      confReplyStudent
      confMediaStudent
      confTerms
      confPresence
      confAdminList
      confRecurrence
      unleashStatus {
        approve_message
        approve_reply
        feature_arquivos
        web_menu_conversations
        show_banner_menu_conversations
        menu_conversations_organization_direct_list
        network_communication
        show_banner_network_communication
      }
      featureToggles {
        web_menu_conversations_notification_bell
      }
      organizationBanners {
        nodes {
          id
          bannerType
        }
      }
      created
      plan
      premiumPeriod
      isDemonstration
      monthlyMessagesCount
      monthlyMessageQuota
      usage
      features {
        messages
        messageManager
        readingControl
        files
        surveys
        commitments
        moments
        reports
        videos
        audios
        charges
        forms
        otherFiles
        dashboard
        api
        support
        labels
        deleteMessages
        editMessages
        links
        videoConference
        arrived {
          completedTimeout
        }
      }
      network {
        id
      }
      tags {
        nodes {
          id: dbId
          name
        }
      }
      permissions {
        entityScopes
        hasAdmin
      }
      arrivedConfiguration {
        id: dbId
      }
      productFeatures {
        totalCount
        nodes {
          id
          identifier
          plan
          paid
          live
          isEnabled(organizationId: $id)
        }
      }
      getLaunchedProductFeature {
        totalCount
        nodes {
          id
          identifier
          plan
          paid
          live
          isEnabled(organizationId: $id)
        }
      }
      paymentMethods {
        name
      }
      ...TopCover
      ...OrganizationHeader
    }
  }
}
${OrganizationHeader.fragments.organization}
${TopCover.fragments.organization}
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.organization_id,
    }
  }),
  skip: ownProps => !ownProps.params.organization_id
})
@graphql(gql`query OrganizationNodeUsernameQuery ($username: String!) {
  organization (username: $username) {
    id: dbId
    color
    live
    link
    type
    username
    fullname
    timezone
    confMessage
    confMessageStudent
    confMessageStaff
    confMedia
    confCommitment
    confSurvey
    confCharge
    confReport
    confForm
    confReplyStudent
    confMediaStudent
    confTerms
    confPresence
    confAdminList
    confRecurrence
    entryMethod
    created
    plan
    premiumPeriod
    isDemonstration
    monthlyMessagesCount
    monthlyMessageQuota
    unleashStatus{
      approve_message
      approve_reply
      web_menu_conversations
      show_banner_menu_conversations
      menu_conversations_organization_direct_list
      network_communication
      show_banner_network_communication
    }
    features {
      messages
      messageManager
      readingControl
      files
      surveys
      commitments
      moments
      reports
      videos
      audios
      charges
      forms
      dashboard
      api
      support
      labels
      deleteMessages
      editMessages
      videoConference
    }
    featureToggles {
      web_menu_conversations_notification_bell
    }
    network {
      id
    }
    tags {
      nodes {
        id: dbId
        name
      }
    }
    permissions {
      entityScopes
      hasAdmin
    }
    productFeatures {
      totalCount
      nodes {
        id
        identifier
        plan
        paid
        live
        # isEnabled(organizationUsername: $username)
      }
    }
    getLaunchedProductFeature {
      totalCount
      nodes {
        id
        identifier
        plan
        paid
        live
        # isEnabled(organizationUsername: $username)
      }
    }
    paymentMethods {
      name
    }
    ...TopCover
    ...OrganizationHeader
  }
}
${OrganizationHeader.fragments.organization}
${TopCover.fragments.organization}
`, {
  options: ownProps => ({
    variables: {
      username: ownProps.params.username
    },
    errorPolicy: 'ignore'
  }),
  skip: ownProps => !ownProps.params.username
})
@observer
export default class OrganizationNode extends React.Component {
  componentDidMount() {
    const { data, store } = this.props;
    const organization = data.node || data.organization;
    store.currentOrganization = organization;
    window.addEventListener('hashchange', () => store.appends.pop());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, store, params, location, router } = nextProps;

    if (((data.loading && (!data.node || data.organization)) || (!data.node && !data.organization)) && !data.error) return;

    if (data.error && data.error.message === 'GraphQL error: Node not found') {
      browserHistory.push(`/${params.organization_id || params.username}/notfound`);
      return;
    } else if (data.error) return;

    const organization = data.node || data.organization;

    // Search for admins in organization
    let myEntity = store.currentUser.entities && store.currentUser.entities
      .find(e => e.type === 'ADMIN' && (e.organization.id == organization.id));

    // Search for staffs with scopes in organization
    if (!myEntity) {
      myEntity = store.currentUser.entities && store.currentUser.entities
        .find(e => e.type === 'STAFF' && e.organization.permissions.entityScopes.length && (e.organization.id == organization.id));
    }

    // Search for admins in another organization or with no organization
    if (!myEntity) {
      myEntity = store.currentUser.entities && store.currentUser.entities
        .find(e => e.type === 'ADMIN');
    }

    if (myEntity) store.currentEntity = myEntity;

    store.app.title = organization.fullname;
    store.currentOrganization = organization;

    const { hasAdmin, entityScopes } = store.currentOrganization.permissions;

    // Redirect admins if current page is for a feature that the organization's plan didn't includes
    const currentPage = location.pathname.split('/');

    let planFeatures = organization.features;
    const productFeatures = organization.productFeatures.nodes;

    if (currentPage[3]) {
      let isFeatureEnabled;

      switch (currentPage[3]) {
        case 'reports':
        case 'forms':
          isFeatureEnabled = planFeatures[currentPage[3]];
          break;
        case 'messages':
          isFeatureEnabled = planFeatures.messageManager !== 'BASIC' || store.currentUser.isMaster;
          break;
        case 'integrations':
          isFeatureEnabled = planFeatures.api;
          break;
        case 'accesses':
          isFeatureEnabled = planFeatures.links;
          break;
        case 'arrived':
          isFeatureEnabled = productFeatures.some(p => p.identifier === 'arrived');
          break;
        default:
          isFeatureEnabled = true;
      }

      if (!isFeatureEnabled) router.push('/organizations/' + currentPage[2]);
    }

    if (hasAdmin || store.currentUser.isMaster) return;

    // Redirect staffs with scopes to the allowed pages
    let allowedPages = [];

    entityScopes.forEach((scope) => {
      if (Object.keys(organizationPages(organization)).includes(scope)) {
        allowedPages = allowedPages.concat(organizationPages(organization)[scope]);
      }
    });

    // User has access to the current page
    if (allowedPages.includes(currentPage[3])) return;

    // User has access to any page from the organization menu
    if (allowedPages.length) {
      if (currentPage[3] === 'enrollment') return;
      router.push(((currentPage.slice(0, 3)).concat(allowedPages[0])).join('/'));
      return;
    }

    // User can't access any page from the organization menu
    router.push('/');
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.currentOrganization = null;
    // store.currentEntity = null;
    window.removeEventListener('hashchange', () => store.appends.pop());
  }

  openPaywall(feature) {
    this.props.store.appends.push(<FeaturesModal feature={feature} />);
  }

  openSideMenuMobile(items) {
    const { location, router } = this.props;
    router.push(location.pathname + location.search + '#side-menu-mobile');
    this.props.store.appends.push(<SideMenuMobile router={this.props.router} items={items} />);
  }

  render() {
    const { data, location, store, router, params } = this.props;
    const { unleash } = store;
    const hasNetworkCommunication = (unleash && unleash.isReady && unleash.getStatus('network_communication'));

    if (!data.loading && params.username && !data.organization) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 96 }}>
          <Container textAlign="center">
            <Message size="large" compact negative>
              <Message.Header>{__('Error (404) - Organization Not Found')}</Message.Header>
              <p>{__('The requested organization %s was not found.', this.props.params.username.replace('/notfound', ''))}</p>
            </Message>
            <Divider section hidden />
            <Footer />
          </Container>
        </div>
      );
    }
    if ((data.loading && (!data.node || data.organization)) ||
      (!data.node && !data.organization) || !store.currentOrganization) return <Loader active />;
    if (!this.props.store.currentEntity) return <Loader active inline="centered" />;

    const organization = data.node || data.organization;

    const hasMenuConversations = organizationShouldSeeConversation({
      currentOrganization: store.currentOrganization,
      unleash: {
        toggles: formatUnleashStatusIntoToggle(organization.unleashStatus),
      },
      currentEntity: store.currentEntity,
      isMaster: store?.currentUser?.isMaster,
    });

    let columnWidth = 13;
    const currentLocation = location.pathname.match(/[a-z]+$/) && location.pathname.match(/[a-z]+$/)[0];
    if (['groups', 'messages', 'reports', 'moments', 'forms', 'accesses'].includes(currentLocation) ||
      location.pathname.indexOf('/reports/') > -1 || location.pathname.indexOf('/forms/') > -1 ||
      (location.pathname.indexOf('/moments') > -1 && currentLocation === 'delete')) {
      columnWidth = 10;
    }

    const { features, permissions, confTerms, productFeatures } = organization;

    const hasMenuArchive = organization?.unleashStatus?.feature_arquivos || unleash.unleash.toggles.find(toggle => toggle.name === 'feature_arquivos')

    let items = [];

    let mobileItems = [];

    if (permissions.hasAdmin || permissions.entityScopes.includes('READ_DASHBOARD') || store.currentUser.isMaster) items.push({ id: 'dashboard', icon: 'tachometer alt', name: __('Dashboard'), to: `/organizations/${organization.id}/dashboard?type=student`, toParams: ['type=student'], 'data-action': 'dashboard' });

    if (permissions.hasAdmin || (permissions.entityScopes.includes('READ_ENTITY') && permissions.entityScopes.includes('READ_GROUP')) || store.currentUser.isMaster) {
      items.push({ id: 'people', icon: 'users', name: __('People'), to: `/organizations/${organization.id}/entities`, 'data-action': 'entities' });
      mobileItems.push({ id: 'people', icon: 'users', name: __('People'), to: `/organizations/${organization.id}/entities`, default: `/organizations/${organization.id}`, 'data-action': 'entities' });
    }

    if (permissions.hasAdmin || (permissions.entityScopes.includes('READ_ENTITY') && permissions.entityScopes.includes('READ_GROUP')) || store.currentUser.isMaster) {
      items.push({ id: 'channels', icon: 'message smile', name: __('Channels'), to: `/organizations/${organization.id}/channels`, 'data-action': 'channels' });
      mobileItems.push({ id: 'channels', icon: 'message smile', name: __('Channels'), to: `/organizations/${organization.id}/channels`, 'data-action': 'channels' });
    }

    if (permissions.hasAdmin || permissions.entityScopes.includes('READ_GROUP') || store.currentUser.isMaster) {
      items.push({ id: 'groups', icon: 'graduation cap', name: __('Groups'), to: `/organizations/${organization.id}/groups`, 'data-action': 'groups' });
      // mobileItems.push({ icon: 'graduation cap', name: __('Groups'), to: `/organizations/${organization.id}/groups`, 'data-action': 'groups' });
    }

    if (((features.messageManager !== 'BASIC' && (permissions.hasAdmin || permissions.entityScopes.includes('READ_MESSAGE'))) || store.currentUser.isMaster)) {
      items.push({ id: 'messages', icon: 'envelope', name: __('Communications'), to: `/organizations/${organization.id}/messages`, 'data-action': 'messages' });
      mobileItems.push({ id: 'messages', icon: 'envelope', name: __('Communications'), to: `/organizations/${organization.id}/messages`, 'data-action': 'messages' });
    } else if (features.messageManager !== 'ADVANCED') {
      items.push({ id: 'messages', icon: 'envelope', name: __('Communications'), to: `/organizations/${organization.id}/messages`, openPaywall: () => { this.openPaywall('MESSAGE_MANAGER'); }, 'data-action': 'messages', rightIcon: 'stars' });
      mobileItems.push({ id: 'messages', icon: 'envelope', name: __('Communications'), to: `/organizations/${organization.id}/messages`, 'data-action': 'messages', openPaywall: () => { this.openPaywall('MESSAGE_MANAGER'); } });
    }

    if (hasMenuConversations && hasConversationProductFeature(organization)) {
      mobileItems.push({ icon: 'message', name: __('Chats'), quantity: store.currentEntity.hasUnreadDirectMessages, to: `/organizations/${organization.id}/chats/manager/channels` });
      items.push({ icon: 'message', name: __('Chats'), to: `/organizations/${organization.id}/chats/manager/channels` });
    }

    if (features.moments && (permissions.hasAdmin || permissions.entityScopes.includes('READ_MESSAGE') || store.currentUser.isMaster)) {
      items.push({ id: 'moments', icon: 'heart', name: __('Moments'), to: `/organizations/${organization.id}/moments`, 'data-action': 'moments' });
      mobileItems.push({ id: 'moments', icon: 'heart', name: __('Moments'), to: `/organizations/${organization.id}/moments`, 'data-action': 'moments' });
    }

    if (features.reports && (permissions.hasAdmin || permissions.entityScopes.includes('READ_REPORT') || store.currentUser.isMaster)) {
      items.push({ id: 'reports', icon: 'list layout', name: __('Reports'), to: `/organizations/${organization.id}/reports`, 'data-action': 'reports' });
    } else if (!features.reports) {
      items.push({ id: 'reports', icon: 'list layout', name: __('Reports'), to: `/organizations/${organization.id}/reports`, openPaywall: () => { this.openPaywall('REPORTS'); }, 'data-action': 'reports', rightIcon: 'stars' });
    }

    if (organization.confCharge) {
      const chargeItem = { icon: 'donate', name: __('ClassPay'), 'data-action': 'charges' };

      if (!utils.isEnumIncluded(confTerms, 'PAYMENT') && (permissions.hasAdmin || permissions.entityScopes.includes('READ_PAYMENT') ||
        permissions.entityScopes.includes('READ_ACCOUNT') || store.currentUser.isMaster)) {
        items.push({ id: 'paymentTerms', ...chargeItem, to: `/organizations/${organization.id}/paymentTerms`, new: true });
        // mobileItems.push({ icon: 'donate', name: __('Payments'), to: `/organizations/${organization.id}/paymentTerms`, 'data-action': 'charges', new: true });
      } else if (permissions.hasAdmin || permissions.entityScopes.includes('READ_PAYMENT') || store.currentUser.isMaster) {
        items.push({ id: 'payments', ...chargeItem, to: `/organizations/${organization.id}/payments`, new: true });
        // mobileItems.push({ icon: 'donate', name: __('Payments'), to: `/organizations/${organization.id}/payments`, 'data-action': 'charges', new: true });
      } else if (permissions.entityScopes.includes('READ_ACCOUNT')) {
        items.push({ id: 'accounts', ...chargeItem, to: `/organizations/${organization.id}/accounts`, new: true });
        // mobileItems.push({ icon: 'donate', name: __('Accounts'), to: `/organizations/${organization.id}/accounts`, 'data-action': 'charges', new: true });
      }
    }

    if (features.forms && (permissions.hasAdmin || permissions.entityScopes.includes('READ_FORM') || store.currentUser.isMaster)) {
      items.push({ id: 'forms', icon: 'poll h', name: __('Forms'), to: `/organizations/${organization.id}/forms`, 'data-action': 'forms' });
    } else if (!features.forms) {
      items.push({ id: 'forms', icon: 'poll h', name: __('Forms'), to: `/organizations/${organization.id}/forms`, openPaywall: () => { this.openPaywall('FORMS'); }, 'data-action': 'forms', rightIcon: 'stars' });
    }

    if (features.api && (permissions.hasAdmin || permissions.entityScopes.includes('READ_INTEGRATION') || store.currentUser.isMaster)) {
      items.push({ id: 'integrations', icon: 'puzzle piece', name: __('Integrations'), to: `/organizations/${organization.id}/integrations`, 'data-action': 'integrations' });
    } else if (!features.api) {
      items.push({ id: 'integrations', icon: 'puzzle piece', name: __('Integrations'), to: `/organizations/${organization.id}/integrations`, openPaywall: () => { this.openPaywall('INTEGRATION'); }, 'data-action': 'integrations', rightIcon: 'stars' });
    }

    if (hasMenuArchive && (permissions.hasAdmin || permissions.entityScopes.includes('READ_FILES')
    || store.currentUser.isMaster))  {
      items.push({
        id: 'files',
        icon: 'archive',
        name: __('Arquivos'),
        to: `/organizations/${organization.id}/files`,
        'data-action': 'files'
      });
    }

    if (features.links && (permissions.hasAdmin || permissions.entityScopes.includes('READ_LINK') || store.currentUser.isMaster)) {
      items.push({ id: 'accesses', icon: 'box open full', name: __('Accesses'), to: `/organizations/${organization.id}/accesses`, 'data-action': 'accesses' });
    } else if (!features.links) {
      items.push({ id: 'accesses', icon: 'box open full', name: __('Accesses'), to: `/organizations/${organization.id}/accesses`, openPaywall: () => { this.openPaywall('ACCESSES'); }, 'data-action': 'accesses', rightIcon: 'stars' });
    }

    if (permissions.hasAdmin || permissions.entityScopes.includes('UPDATE_ORGANIZATION') || store.currentUser.isMaster) {
      items.push({ id: 'edit', icon: 'cog', name: __('Settings (menu)'), to: `/organizations/${organization.id}/edit`, 'data-action': 'settings' });
    }

    items.push({ id: 'enrollment', icon: 'gift', name: __('Enrollment'), 'data-action': 'enrollment', to: `/organizations/${organization.id}/enrollment` });

    const hasSeenArrivedIntroPage = organization.arrivedConfiguration !== null;
    const isArrivedLaunched = organization.getLaunchedProductFeature.nodes.some(feature => feature.identifier === 'arrived');

    if (permissions.hasAdmin || store.currentUser.isMaster) {
      if (productFeatures.nodes.some(p => p.identifier === 'arrived')) {
        if (hasSeenArrivedIntroPage) {
          items.push({ id: 'arrived', icon: 'car', name: __('Arrived'), to: `/organizations/${organization.id}/arrived/history`, 'data-action': 'arrived' });
        } else {
          items.push({ id: 'arrived', icon: 'car', name: __('Arrived'), to: `/organizations/${organization.id}/arrived/intro`, 'data-action': 'arrived' });
        }
      } else if (isArrivedLaunched) {
        // Paywall will be changed to langing page
        // items.push({ id: 'arrived', icon: 'car', name: __('Arrived'), to: `/organizations/${organization.id}/arrived/history`, openPaywall: () => { this.openPaywall('ARRIVED'); }, 'data-action': 'arrived', rightIcon: 'stars' });
      }
    }

    mobileItems.push({ id: 'more-actions', icon: 'ellipsis stroke vertical', name: __('More actions'), onClick: () => { this.openSideMenuMobile(items); }, 'data-action': 'more-actions' });
    // if (((permissions.entityScopes.includes('READ_ENTITY') && permissions.entityScopes.includes('READ_RECURRENCE') && permissions.entityScopes.includes('READ_GROUP')) || store.currentUser.isMaster || permissions.hasAdmin) && organization.confRecurrence) {
    //   items.push({ icon: 'sack dollar', name: __('Recurrence'), to: `/organizations/${organization.id}/recurrence`, 'data-action': 'recurrence' });
    // }

    // if (((permissions.entityScopes.includes('READ_ENTITY') && permissions.entityScopes.includes('READ_RECURRENCE') && permissions.entityScopes.includes('READ_GROUP')) || store.currentUser.isMaster || permissions.hasAdmin) && organization.confRecurrence) {
    //   items.push({ icon: 'sack dollar', name: __('Recurrence'), to: `/organizations/${organization.id}/recurrence`, 'data-action': 'recurrence' });
    // }

    return (
      <Container desktopItems={items} mobileItems={mobileItems} router={router} id="OrganizationNodeMenu">
        {this.props.children}
      </Container>
    );
  }
}